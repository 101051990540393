import env from "@utils/env";

declare global {
  interface Window {
    hsConversationsSettings: Object;
    HubSpotConversations: Object;
    hsConversationsOnReady: Object;
  }
}

if (!location.pathname.startsWith("/internal/")) {
  const hubspotLoadScript = `//js.hs-scripts.com/${
    env.CLIENTS_APP_HUBSPOT_ID
  }.js`;

  const scriptTag = document.createElement("script");
  scriptTag.setAttribute("id", "hs-script-loader");
  scriptTag.setAttribute("src", hubspotLoadScript);
  document.head.appendChild(scriptTag);

  function onConversationsAPIReady() {
    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);
  }
  window.hsConversationsSettings = {};
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
}
