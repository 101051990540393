import env from "@utils/env";
import { ref } from "vue";
import { Role } from "./useRole";

export const IAEnabledRoles = ref<string[]>();

const isDev = () => {
  return env.CLIENTS_FLAGSMITH_ENVIRONMENT === "dYXUA8spboYZWaPkVSDJ8d";
}

export const isIAEnabled = (role?: Role) => {
  return !!(role && (isDev() || IAEnabledRoles.value?.includes(role.id) || role.publishedAt >= new Date('2024-10-28')));
}
