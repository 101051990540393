<template>
  <router-view v-if="isLoaded" />
  <loading-overlay v-else />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import LoadingOverlay from "./components/LoadingOverlay.vue";
import identifyUser from "@clients/lib/identify";

const isLoaded = ref(false);

onMounted(async () => {
  try {
    await identifyUser();
  } finally {
    isLoaded.value = true;
  }
});
</script>
