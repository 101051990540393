<template>
  <section
    class="w-screen h-screen flex items-center justify-center bg-ivory-200 z-100 fixed top-0 left-0 bottom-0 right-0"
  >
    <img
      alt="Nurole"
      src="@clients/assets/images/nurole-initials.svg"
      class="animate-logo"
    />
  </section>
</template>

<style scoped>
.animate-logo {
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}
</style>
